<template>
  <v-select :options="options"></v-select>
</template>

<script>
import vSelect from 'vue-select'
export default {
  props: {
    options: Array,
  },
  components: { vSelect },
}
</script>

<style>
.vs__dropdown-toggle {
  border: 1px solid var(--teamLightGray);
  border-radius: 10px;
}
.vs__selected-options,
.vs__selected {
  font-size: 14px;
  color: var(--teamBlue) !important;
}
.vs__selected-options {
  padding: 6px 8px !important;
}
.vs__clear {
  display: none;
}
.vs__open-indicator {
  fill: var(--teamBlue) !important;
  margin-right: 10px;
}
.vs__dropdown-menu {
  background: var(--teamSand) !important;
}
.vs__dropdown-option--highlight {
  color: var(--teamBlue) !important;
  background: var(--teamLightGray) !important;
}
</style>
