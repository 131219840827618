const branches = [
  {
    id: '1',
    cord: [40.196706, 44.565861],
    phone: '+37495288221',
    city: 'branches.branch1.city',
    days: {
      monFry: 'branches.branch1.days.monFry.text',
      satSun: 'branches.branch1.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch1.days.monFry.hours',
      satSun: 'branches.branch1.days.satSun.hours',
    },
    street: 'branches.branch1.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '2',
    cord: [40.206225, 44.518269],
    city: 'branches.branch2.city',
    days: {
      monFry: 'branches.branch2.days.monFry.text',
      satSun: 'branches.branch2.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch2.days.monFry.hours',
      satSun: 'branches.branch2.days.satSun.hours',
    },
    street: 'branches.branch2.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '3',
    cord: [40.208464, 44.4679],
    city: 'branches.branch3.city',
    days: {
      monFry: 'branches.branch3.days.monFry.text',
      satSun: 'branches.branch3.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch3.days.monFry.hours',
      satSun: 'branches.branch3.days.satSun.hours',
    },
    street: 'branches.branch3.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '4',
    cord: [40.150288, 44.483486],
    city: 'branches.branch4.city',
    days: {
      monFry: 'branches.branch4.days.monFry.text',
      satSun: 'branches.branch4.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch4.days.monFry.hours',
      satSun: 'branches.branch4.days.satSun.hours',
    },
    street: 'branches.branch4.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '5',
    cord: [40.141802, 44.520631],
    city: 'branches.branch5.city',
    days: {
      monFry: 'branches.branch5.days.monFry.text',
      satSun: 'branches.branch5.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch5.days.monFry.hours',
      satSun: 'branches.branch5.days.satSun.hours',
    },
    street: 'branches.branch5.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '6',
    cord: [40.184112, 44.514595],
    city: 'branches.branch6.city',
    days: {
      monFry: 'branches.branch6.days.monFry.text',
      satSun: 'branches.branch6.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch6.days.monFry.hours',
      satSun: 'branches.branch6.days.satSun.hours',
    },
    street: 'branches.branch6.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '7',
    cord: [40.178586, 44.510552],
    city: 'branches.branch7.city',
    days: {
      monFry: 'branches.branch7.days.monFry.text',
      satSun: 'branches.branch7.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch7.days.monFry.hours',
      satSun: 'branches.branch7.days.satSun.hours',
    },
    street: 'branches.branch7.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '8',
    cord: [40.173797, 44.446394],
    city: 'branches.branch8.city',
    days: {
      monFry: 'branches.branch8.days.monFry.text',
      satSun: 'branches.branch8.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch8.days.monFry.hours',
      satSun: 'branches.branch8.days.satSun.hours',
    },
    street: 'branches.branch8.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '9',
    cord: [40.170069, 44.513876],
    city: 'branches.branch9.city',
    days: {
      monFry: 'branches.branch9.days.monFry.text',
      satSun: 'branches.branch9.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch9.days.monFry.hours',
      satSun: 'branches.branch9.days.satSun.hours',
    },
    street: 'branches.branch9.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '10',
    cord: [40.209607, 44.530513],
    city: 'branches.branch10.city',
    days: {
      monFry: 'branches.branch10.days.monFry.text',
      satSun: 'branches.branch10.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch10.days.monFry.hours',
      satSun: 'branches.branch10.days.satSun.hours',
    },
    street: 'branches.branch10.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '11',
    cord: [40.216101, 44.578761],
    city: 'branches.branch11.city',
    days: {
      monFry: 'branches.branch11.days.monFry.text',
      satSun: 'branches.branch11.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch11.days.monFry.hours',
      satSun: 'branches.branch11.days.satSun.hours',
    },
    street: 'branches.branch11.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '12',
    cord: [40.270676, 44.626471],
    city: 'branches.branch12.city',
    days: {
      monFry: 'branches.branch12.days.monFry.text',
      satSun: 'branches.branch12.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch12.days.monFry.hours',
      satSun: 'branches.branch12.days.satSun.hours',
    },
    street: 'branches.branch12.street',
    region: {
      id: 'branches.regions.kotayk.id',
      label: 'branches.regions.kotayk.label',
    },
  },
  {
    id: '13',
    cord: [40.547739, 44.955296],
    city: 'branches.branch13.city',
    days: {
      monFry: 'branches.branch13.days.monFry.text',
      satSun: 'branches.branch13.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch13.days.monFry.hours',
      satSun: 'branches.branch13.days.satSun.hours',
    },
    street: 'branches.branch13.street',
    region: {
      id: 'branches.regions.gegharkunik.id',
      label: 'branches.regions.gegharkunik.label',
    },
  },
  {
    id: '14',
    cord: ['40.808989', '44.492810'],
    city: 'branches.branch14.city',
    days: {
      monFry: 'branches.branch14.days.monFry.text',
      satSun: 'branches.branch14.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch14.days.monFry.hours',
      satSun: 'branches.branch14.days.satSun.hours',
    },
    street: 'branches.branch14.street',
    region: {
      id: 'branches.regions.lori.id',
      label: 'branches.regions.lori.label',
    },
  },
  {
    id: '15',
    cord: [40.787594, 43.848161],
    city: 'branches.branch15.city',
    days: {
      monFry: 'branches.branch15.days.monFry.text',
      satSun: 'branches.branch15.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch15.days.monFry.hours',
      satSun: 'branches.branch15.days.satSun.hours',
    },
    street: 'branches.branch15.street',
    region: {
      id: 'branches.regions.shirak.id',
      label: 'branches.regions.shirak.label',
    },
  },
  {
    id: '16',
    cord: [39.956583, 44.545083],
    city: 'branches.branch16.city',
    days: {
      monFry: 'branches.branch16.days.monFry.text',
      satSun: 'branches.branch16.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch16.days.monFry.hours',
      satSun: 'branches.branch16.days.satSun.hours',
    },
    street: 'branches.branch16.street',
    region: {
      id: 'branches.regions.ararat.id',
      label: 'branches.regions.ararat.label',
    },
  },
  {
    id: '17',
    cord: [40.203284, 44.500599],
    city: 'branches.branch17.city',
    days: {
      monFry: 'branches.branch17.days.monFry.text',
      satSun: 'branches.branch17.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch17.days.monFry.hours',
      satSun: 'branches.branch17.days.satSun.hours',
    },
    street: 'branches.branch17.street',
    region: {
      id: 'branches.regions.yerevan.id',
      label: 'branches.regions.yerevan.label',
    },
  },
  {
    id: '18',
    cord: [40.163239, 44.294238],
    city: 'branches.branch18.city',
    days: {
      monFry: 'branches.branch18.days.monFry.text',
      satSun: 'branches.branch18.days.satSun.text',
    },
    hours: {
      monFry: 'branches.branch18.days.monFry.hours',
      satSun: 'branches.branch18.days.satSun.hours',
    },
    street: 'branches.branch18.street',
    region: {
      id: 'branches.regions.armavir.id',
      label: 'branches.regions.armavir.label',
    },
  },
]

export default branches
